import { render, staticRenderFns } from "./CustomPetPage.vue?vue&type=template&id=678f9dd8&scoped=true"
import script from "./CustomPetPage.vue?vue&type=script&lang=js"
export * from "./CustomPetPage.vue?vue&type=script&lang=js"
import style0 from "./CustomPetPage.vue?vue&type=style&index=0&id=678f9dd8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "678f9dd8",
  null
  
)

export default component.exports