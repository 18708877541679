<template>
	<div class="page scroll">
		<ParticleBackground fullscreen />
		<CustomPetCard class="card" :loading="fetching" />
	</div>
</template>

<script>
import ParticleBackground from '@/components/particles/ParticleBackground';
import CustomPetCard from '@/views/custom-pet/components/CustomPetCard';

export default {
	name: 'CustomPetPage',
	components: {
		ParticleBackground,
		CustomPetCard,
	},
	data: () => ({
		fetching: true,
	}),
	computed: {
		isLoggedIn() {
			return !!this.$store.getters.user?.id;
		},
	},
	methods: {
	},
	async mounted() {
		this.fetching = true;
		try {
			await this.$store.dispatch('getCustomPets');
		} catch (err) {
			console.error(err);
		} finally {
			this.fetching = false;
		}
	},
};
</script>

<style scoped>
.page {
	position: relative;
	min-height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.scroll::-webkit-scrollbar-track {
	background: var(--v-background_dark2-base);
	border-left: 1px solid var(--v-background-base);
}

.scroll::-webkit-scrollbar-thumb {
	background: var(--v-background-base);
	border: solid 3px var(--v-background_dark2-base);
	border-radius: 7px;
}

.scroll::-webkit-scrollbar-thumb:hover {
	background: var(--v-secondary-base);
}

.card {
	margin: 100px 0px;
}
</style>
