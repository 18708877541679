<template>
	<div>
		<v-card class="log-card" :style="cardBorder">
			<span v-if="loading" class="loading">
				<v-card-title> Fetching Custom Pets... </v-card-title>
				<LoadingSpinner :size="30" />
			</span>
			<keep-alive v-else>
				<router-view></router-view>
			</keep-alive>
		</v-card>
	</div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner';
import CustomPetTable from '@/views/custom-pet/components/CustomPetTable';

export default {
	name: 'CustomPetCard',
	components: { LoadingSpinner, CustomPetTable },
	data: () => ({
		redirect: process.env.VUE_APP_BACKEND + '/api/auth/discord',
		loading: true,
	}),
	computed: {
		cardBorder() {
			return 'border: 3px solid var(--v-primary-base);';
		},
	},
	methods: {
	},
	async mounted() {
		this.loading = true;
		try {
			await this.$store.dispatch('getCustomPets');
		} catch (err) {
			console.error(err);
		} finally {
			this.loading = false;
		}
	}
};
</script>

<style scoped>
.log-card {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	transition: all 0.5s ease;
	min-width: 400px;
	min-height: 300px;
}

.loading {
	min-height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

</style>
